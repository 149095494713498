<template>
  <v-app-bar
    id="menu-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="var(--primary-black-color)"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    :height="barHeight"
  >
    <!-- :class="$route.name == 'Home' ? 'align-start' : 'align-center'" -->
    <div class="d-flex justify-space-between align-center flex-grow-1 w-100 h-100">
      <div class="left-wrap">
        <v-icon v-if="!hideBackBtn" color="var(--primary-color)" size="30" @click="$router.go(-1)">mdi-arrow-left</v-icon>
        <div v-else-if="showLogo" class="d-flex left-wrap">
          <img
            class="logo cursor-pointer"
            src="@/assets/logo-yellow.svg"
            @click="navigatePush('Main', null, { storeId: $route.query.storeId })"
          />
        </div>
      </div>
      <div class="center-wrap flex-grow-1 text-center">
        <div class="bar-title">{{ drawerTitle }}</div>
      </div>
      <slot name="right-item">
        <div class="right-wrap"></div>
      </slot>
    </div>
    <v-spacer />
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';
import { DELIVERY_CHANNEL_PATH, DELIVERY_STEP } from '@/common/enum/checkout';
export default {
  name: 'DefaultBar',

  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      drawerTitle: state => state.app.drawerTitle,
    }),
    barHeight() {
      return this.isMobileView ? 65 : 75;
    },
    hideBackBtn() {
      const params = this.$route.params;
      const query = this.$route.query;

      const condition1Route = ['OrderDetails', 'OrderPayment', 'ReferrerTransition'];
      const condition1 = condition1Route.indexOf(this.$route.name) != -1;
      // const condition2 =
      //   this.$route.name == 'OrderCheckout' &&
      //   params.channel == DELIVERY_CHANNEL_PATH.DELIVERY &&
      //   query.step == DELIVERY_STEP.CUSTOMER_INFO;
      return condition1 ? true : false;
    },
    showLogo() {
      // const condition1 = this.$route.name == 'OrderPayment';
      // return condition1 ? true : false;
      return false;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@mixin for-sm {
  @media (max-width: 599px) {
    @content;
  }
}

#menu-app-bar::v-deep {
  box-shadow: none;

  .v-toolbar__content {
    padding: 15px 20px 15px;
    @include for-sm {
      padding: 10px 20px 10px;
    }
  }

  .header-text {
    color: var(--primary-color);
    font-size: 1.75rem;
    font-weight: 600;
  }

  .left-wrap {
    max-width: 100px;
    text-align: left;
    width: 100%;
  }

  .bar-title {
    color: var(--primary-color);
    font-size: 1.75rem;
    font-weight: 500;
  }

  .right-wrap {
    max-width: 100px;
    text-align: right;
    width: 100%;
  }
}
.icon {
  width: 40px;
}
.logo {
  max-width: 55px;
  width: 100%;
}
</style>
